import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Modal from '_components/modal/generic-modal'
import { FormProvider, useForm } from 'react-hook-form'
import { Attachments } from '_components/refera-ui'
import { Typography } from '@mui/material'
import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { serviceOrderCurrentSelector } from '_/modules/service-orders/selectors'
import { useParams } from '@reach/router'
import classNames from 'classnames'
import { sendInvoice } from '_/modules/service-orders/actions'
import { getIsXmlRequiredToFinishServiceOrder } from '_/modules/finance/actions'
import { isXmlRequiredToFinishServiceOrderSelector } from '_/modules/finance/selectors'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { useDialog } from '_/hooks/use-dialog'
// import { getBudgetByIdSelector } from '_/modules/budget/selectors'
// import { BasicInput } from '_/components/inputs'

const DEFAULT_ATTACHMENTS = { pdf: [], xml: [] }
const INVOICE_PDF_MAX_SIZE = 1000000
const INVOICE_XML_MAX_SIZE = 500 * 1024 // 500KB

export function InvoiceModal({ open, onClose, isEditing }) {
  const styles = useStyles()
  const {
    serviceOrderId,
    // , budgetId
  } = useParams()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()

  const serviceOrder = useSelector(serviceOrderCurrentSelector)
  const isXmlRequiredToFinishServiceOrder = useSelector(isXmlRequiredToFinishServiceOrderSelector)
  // const budget = useSelector(getBudgetByIdSelector(budgetId))

  const [attachments, setAttachments] = useState(DEFAULT_ATTACHMENTS)
  const [loading, setLoading] = useState(true)

  const methods = useForm({
    mode: 'all',
  })

  // const invoiceNumber = methods.watch('invoiceNumber')

  const handleDownloadInvoice = extension => {
    window.open(attachments?.[extension]?.[0]?.file)
  }

  const handleRemoveInvoice = extension => {
    setAttachments(prev => ({ ...prev, [extension]: [] }))
  }

  const convertAddinToXml = addinFile => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        const fileContent = reader.result

        const xmlMatch = fileContent.match(/<\?xml[^>]*>[\s\S]*<\/[\s\S]+>/)

        if (xmlMatch) {
          const xmlContent = xmlMatch[0]
          const xmlBlob = new Blob([xmlContent], { type: 'application/xml' })
          resolve(xmlBlob)
        } else {
          reject(new Error('Arquivo ADDIN não contém XML válido.'))
        }
      }

      reader.onerror = () => {
        reject(new Error('Erro ao ler o arquivo ADDIN.'))
      }

      reader.readAsText(addinFile)
    })
  }

  const handleAddInvoice = (extension, file) => {
    if (
      (extension === 'pdf' && file[0].size > INVOICE_PDF_MAX_SIZE) ||
      (extension !== 'pdf' && file[0].size > INVOICE_XML_MAX_SIZE)
    ) {
      showToast({
        type: 'error',
        message: `O arquivo ultrapassou o limite de tamanho permitido: ${
          extension === 'pdf' ? '1MB' : '500KB'
        }`,
      })
      return
    }

    if (extension !== 'pdf') {
      convertAddinToXml(file[0])
        .then(xmlBlob => {
          const xmlFile = new File([xmlBlob], 'nota_fiscal.xml', {
            type: 'application/xml',
          })
          setAttachments(prev => ({ ...prev, xml: [xmlFile] }))
        })
        .catch(() => {
          showToast({
            type: 'error',
            message: 'Erro ao converter arquivo em XML.',
          })
        })
    } else {
      setAttachments(prev => ({ ...prev, [extension]: file }))
    }
  }

  const isSubmitButtonDisabled = useMemo(() => {
    return (
      loading ||
      !attachments?.pdf.length ||
      (isXmlRequiredToFinishServiceOrder?.isXmlRequired && !attachments?.xml.length) ||
      (attachments?.pdf[0]?.id && attachments?.xml[0]?.id) // already uploaded
    )
  }, [attachments, loading, isXmlRequiredToFinishServiceOrder])

  // const handleRemoveInvoice = useCallback(
  //   async index => {
  //     const confirmed = await isConfirmed()
  //     if (confirmed) {
  //       setIsLoading(true)
  //       dispatch(deleteInvoice(serviceOrderId))
  //         .then(() => {
  //           setInvoices(cur => cur.filter((_, i) => index !== i))
  //         })
  //         .finally(() => {
  //           setIsLoading(false)
  //         })
  //     }
  //   },
  //   [invoices]
  // )

  const handleSendInvoice = useCallback(async () => {
    const formData = new FormData()

    const pdfFile = attachments?.pdf[0]
    const xmlFile = attachments?.xml[0]

    // checking if the file is already uploaded
    if (!pdfFile?.id) formData.append('attachment_pdf', pdfFile)
    if (xmlFile && !xmlFile?.id) formData.append('attachment_xml', xmlFile)

    await dispatch(sendInvoice(serviceOrderId, formData))
      .then(onClose)
      .catch(err => {
        showDialog({
          type: 'error',
          subject: 'Erro ao enviar notas fiscais',
          description: (
            <span style={{ whiteSpace: 'pre-wrap' }}>{formatErrorMessage(err, 1000)}</span>
          ),
          onApprove: closeDialog,
          labelApprove: 'Ok',
        })
      })
  }, [attachments, onClose])

  useEffect(() => {
    if (serviceOrder?.get('attachments').size > 0) {
      const invoiceList = serviceOrder
        .get('attachments')
        .filter(invoice => ('invoice', 'invoice_xml').includes(invoice.get('fileType')))
        .map(invoice => ({
          file: invoice?.get('file'),
          id: invoice?.get('id'),
          fileType: invoice?.get('fileType'),
        }))
        .toArray()

      setAttachments({
        pdf: invoiceList.filter(invoice => invoice.fileType === 'invoice'),
        xml: invoiceList.filter(invoice => invoice.fileType === 'invoice_xml'),
      })
    }
  }, [serviceOrder?.get('attachments')])

  useEffect(() => {
    setLoading(true)
    dispatch(getIsXmlRequiredToFinishServiceOrder(serviceOrderId))
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }, [])

  return (
    <Modal.Root open={open} onClose={onClose}>
      <Modal.TitleModal title="Nota Fiscal" />
      <Modal.Content className={styles.content}>
        <FormProvider {...methods}>
          <form className={styles.form} onSubmit={e => e.preventDefault()}>
            {/* <BasicInput
              label="Número da NF"
              name="invoiceNumber"
              type="number"
              required
              labelClasses={styles.inputLabel}
              defaultValue={budget?.invoice}
              rules={{
                maxLength: { value: 19, message: 'Esse campo atingiu o limite de caracteres' },
                required: { value: true, message: 'Esse campo é obrigatório' },
              }}
            /> */}

            {serviceOrder && (
              <>
                <div className={styles.attachmentsContainer}>
                  <Typography
                    className={classNames(
                      styles.attachmentLabel,
                      styles.inputLabel,
                      styles.required
                    )}
                  >
                    Nota fiscal formato PDF
                  </Typography>
                  <Attachments
                    label="Nota fiscal formato PDF"
                    content="files"
                    readOnly={!isEditing}
                    files={attachments?.pdf}
                    multiple={false}
                    downloadable
                    accept={{ 'application/pdf': ['.pdf'] }}
                    onDrop={file => handleAddInvoice('pdf', file)}
                    onRemoveItem={() => handleRemoveInvoice('pdf')}
                    onItemClick={() => handleDownloadInvoice('pdf')}
                  />
                </div>
                {isXmlRequiredToFinishServiceOrder?.isXmlRequired && (
                  <div className={styles.attachmentsContainer}>
                    <Typography
                      className={classNames(
                        styles.attachmentLabel,
                        styles.inputLabel,
                        isXmlRequiredToFinishServiceOrder?.isXmlRequired && styles.required
                      )}
                    >
                      Nota fiscal formato XML
                    </Typography>
                    <Attachments
                      label="Nota fiscal formato XML"
                      content="files"
                      readOnly={!isEditing}
                      files={attachments?.xml}
                      multiple={false}
                      downloadable
                      accept={{
                        'application/xml': [
                          '.xml',
                          '.addin',
                          '.asa',
                          '.txt',
                          '.json',
                          '.nfe',
                          '.nfse',
                          '.xmlnfe',
                        ],
                      }}
                      onDrop={file => {
                        const extension = file[0].name.split('.').pop().toLowerCase()
                        handleAddInvoice(extension, file)
                      }}
                      onRemoveItem={() => handleRemoveInvoice('xml')}
                      onItemClick={() => handleDownloadInvoice('xml')}
                    />
                  </div>
                )}
              </>
            )}
          </form>
        </FormProvider>
      </Modal.Content>
      <Modal.Actions>
        <Modal.ButtonRed onClick={onClose}>Cancelar</Modal.ButtonRed>
        <Modal.ButtonFullBlue
          onClick={methods.handleSubmit(handleSendInvoice)}
          disabled={isSubmitButtonDisabled}
        >
          Confirmar
        </Modal.ButtonFullBlue>
      </Modal.Actions>
    </Modal.Root>
  )
}
