import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  dot: {
    display: 'block',
    width: spacing(32),
    height: spacing(32),
    borderRadius: '50%',
  },
  greenDot: {
    background: `radial-gradient(circle at center, ${Theme.Colors.Green.Base} 33%, ${Theme.Colors.Green.Lightest} 34%)`,
  },
  redDot: {
    background: `radial-gradient(circle at center, ${Theme.Colors.Red.Base} 33%, ${Theme.Colors.Red.Lightest} 34%)`,
  },
  grayDot: {
    background: `radial-gradient(circle at center, ${Theme.Colors.Grayscale.ThirtyTwo} 33%, ${Theme.Colors.Grayscale.Twelve} 34%)`,
  },
  stepStatus: {
    color: '#000',
    lineHeight: 1.42857142857,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Medium,
  },
  info: {
    color: Theme.Colors.Grayscale.OneThousand,
    lineHeight: 1.42857142857,
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Small,
  },
  emptyInfo: {
    height: spacing(44),
    display: 'flex',
    alignItems: 'center',
  },
  centerDot: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    // TODO: Remove !important once refera lib support override this class
    padding: '0 !important',
    color: Theme.Colors.Primary.Base,
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontWeight: Theme.Typography.FontWeight.Small,
  },
}))
