import React, { useState, useCallback, useMemo } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Button, Loader, Dialog as ReferaDialog, Typography } from '@refera/ui-web'
import { Danger as InfoDangerIcon } from '@refera/ui-icons'
import { DateTimePicker } from '_components/inputs'

import useStyles from './styles'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { serviceOrderCurrentSelector } from '_modules/service-orders/selectors'
import moment from 'moment'
import { registerExecution } from '_/modules/service-orders/actions'
import { useToast } from '_/hooks/use-toast'
import { getStepStatusLog } from '_/modules/budget/actions'
import { useNavigate } from '@reach/router'
import { ROUTES } from '_/utils/constants'
import { STEP_STATUS } from '_/utils/constants/service-order'
import { FormHelperText, TextField } from '@mui/material'

const defaultDialog = {
  isOpen: false,
  subject: '',
  icon: InfoDangerIcon,
  iconType: 'info',
  description: '',
  labelApprove: 'Ok, entendi',
  onApprove: () => {},
}

export function FinishServiceModal({
  open,
  onClose,
  onCancel,
  executionProofs,
  requesterRatingFeature,
}) {
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const { showToast } = useToast()
  const [dialog, setDialog] = useState(defaultDialog)

  const navigate = useNavigate()

  const styles = useStyles()

  const dispatch = useDispatch()
  const serviceOrder = useSelector(serviceOrderCurrentSelector)
  const serviceOrderExecutedAt = serviceOrder?.get('executedAt')

  const showInputs = useMemo(() => {
    if (!serviceOrder) return false

    return (
      serviceOrder?.businessFront.get('name') === 'emergency' &&
      serviceOrder?.stepStatus === STEP_STATUS.WAITING_FOR_SERVICE_EXECUTION
    )
  }, [serviceOrder])

  const defaultValues = {
    executedAt: serviceOrderExecutedAt || null,
    issueFound: serviceOrder?.issueFound,
    solutionApplied: serviceOrder?.solutionApplied,
  }

  const methods = useForm({
    defaultValues,
    mode: 'all',
  })

  const { control, errors } = methods

  const checkRatingIsNotNecessary =
    !serviceOrder?.isReferaService && serviceOrder?.agency?.get('contractType') === 'SAAS'

  const saveServiceExecution = useCallback(
    async data => {
      setLoading(true)
      dispatch(
        registerExecution(serviceOrder?.get('id'), {
          ...data,
          executedAt: data?.executedAt.toISOString(),
        })
      )
        .then(() => {
          showToast({ type: 'success' })
          setSaveDisabled(false)
          dispatch(getStepStatusLog(serviceOrder?.get('id'))).then(() => {
            setLoading(false)
            if (!checkRatingIsNotNecessary && serviceOrder?.get('requesterRatingFeature')) {
              navigate(`${ROUTES.SEND_RATING_LINK}/${serviceOrder?.get('id')}`)
            } else {
              navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrder?.get('id')}`)
            }
            onClose()
          })
        })
        .catch(() => {
          setSaveDisabled(false)
          setLoading(false)
          showToast({ type: 'error' })
        })
    },
    [onClose, serviceOrder?.get('id'), checkRatingIsNotNecessary]
  )

  const handleSaveButtonClick = useCallback(
    async data => {
      if (!methods.formState.isDirty) {
        onClose()
        return
      }

      setSaveDisabled(true)
      const executedAt = moment(data.executedAt)
      const now = moment()

      if (executedAt.isAfter(now)) {
        setSaveDisabled(false)
        showToast({
          type: 'error',
          message: 'A data e hora devem ser no passado.',
        })
        return
      }

      if (!executionProofs?.length && requesterRatingFeature) {
        setDialog({
          isOpen: true,
          iconType: 'warning',
          icon: InfoDangerIcon,
          labelApprove: 'Ok, entendi',
          subject: 'Atenção!',
          description:
            'Você não pode finalizar um serviço sem enviar fotos. Envie fotos para depois finalizar o serviço.',
          onApprove: () => setDialog(prev => ({ ...prev, isOpen: false })),
        })
        setSaveDisabled(false)

        return
      }

      await saveServiceExecution({ ...data, executedAt })
    },
    [methods.formState.isDirty, saveServiceExecution, onClose, requesterRatingFeature]
  )

  return (
    <>
      <Dialog open={open} className={styles.dialog} keepMounted={false}>
        <div key="finishServiceModalHeader" className={styles.header}>
          <DialogTitle className={styles.title}>Finalizar serviço</DialogTitle>
        </div>
        <DialogContent className={styles.content}>
          <FormProvider {...methods}>
            <form
              key="finishServiceModalForm"
              id="finishServiceModalForm"
              className={styles.form}
              onSubmit={e => e.preventDefault()}
              noValidate
            >
              <Typography component="p" className={styles.p}>
                Ao salvar, você está informando a Refera que o serviço foi finalizado. Agora será
                necessário que você envie o link de avaliação do seu serviço para o cliente.
              </Typography>
              <DateTimePicker name="executedAt" label="Data do serviço finalizado" required />
              {showInputs && (
                <>
                  <div className={styles.column}>
                    <h1 className={styles.label}>
                      Problema encontrado <span className={styles.errorMessage}>*</span>
                    </h1>
                    <Controller
                      control={control}
                      name="issueFound"
                      rules={{ required: { value: true, message: 'Este campo é obrigatório' } }}
                      render={field => {
                        return (
                          <TextField
                            className={styles.textArea}
                            id="issueFound"
                            placeholder="Descreva ao cliente qual o problema que ocorria no local."
                            InputLabelProps={{ shrink: true }}
                            multiline
                            error={!!errors?.issueFound}
                            maxRows={15}
                            minRows={3}
                            required
                            InputProps={{ style: { fontSize: 16, borderRadius: 8 } }}
                            {...field}
                          />
                        )
                      }}
                    />
                    <FormHelperText className={styles.errorMessage} error={!!errors?.issueFound}>
                      {errors?.issueFound?.message}
                    </FormHelperText>
                  </div>

                  <div className={styles.column}>
                    <h1 className={styles.label}>
                      Solução realizada <span className={styles.errorMessage}>*</span>
                    </h1>
                    <Controller
                      control={control}
                      name="solutionApplied"
                      rules={{ required: { value: true, message: 'Este campo é obrigatório' } }}
                      render={field => {
                        return (
                          <TextField
                            className={styles.textArea}
                            id="solutionApplied"
                            placeholder="Descreva ao cliente o que você fez para solucionar o problema."
                            InputLabelProps={{ shrink: true }}
                            multiline
                            error={!!errors?.solutionApplied}
                            maxRows={15}
                            minRows={3}
                            required
                            InputProps={{ style: { fontSize: 16, borderRadius: 8 } }}
                            {...field}
                          />
                        )
                      }}
                    />
                    <FormHelperText
                      className={styles.errorMessage}
                      error={!!errors?.solutionApplied}
                    >
                      {errors?.solutionApplied?.message}
                    </FormHelperText>
                  </div>
                </>
              )}
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={onCancel} variant="secondary" color="red" className={styles.button}>
            Cancelar
          </Button>
          <Button
            onClick={methods.handleSubmit(handleSaveButtonClick)}
            className={styles.button}
            type="button"
            disabled={saveDisabled || loading}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {dialog?.isOpen && (
        <ReferaDialog
          open={dialog?.isOpen}
          type={dialog?.iconType}
          icon={dialog?.icon}
          subject={dialog?.subject}
          description={dialog?.description}
          labelApprove={dialog?.labelApprove}
          onApprove={dialog?.onApprove}
          labelCancel={dialog?.labelCancel}
          onCancel={dialog?.onCancel}
        />
      )}
      <Loader hasBackdrop open={loading} label="Aguarde..." />
    </>
  )
}
